import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useQueryParam, StringParam } from "use-query-params"
import AOS from "aos"
import validateEmail from "../../utils/validateEmail"

import success from "../../images/pictures/podcasts/customer_success.png"

import ebooks from "../../data/ebooks.json"
import "./miniebookpage.scss"
import "aos/dist/aos.css"

const MiniEbookPage = () => {
  const [id] = useQueryParam("id", StringParam)
  const [ebook, setEbook] = useState({
    id: "",
    title: "",
    link: "",
    image: "",
    description: [""],
    author: "",
    date: "",
  })
  const [emailIsValid, setEmailIsValid] = useState(false)
  const [email, setEmail] = useState("")
  const [image, setImage] = useState(success)

  function handleSubmitForm(event) {
    event.preventDefault()

    if (!emailIsValid) {
      event.stopPropagation()

      return
    }

    setTimeout(() => {
      setEmail("")
      setEmailIsValid(false)
    }, 2000)

    alert("Cadastrado")
    window.open(ebook.link)
  }

  function handleEmailChange(event) {
    const { value } = event.target
    setEmail(value)
    setEmailIsValid(validateEmail(value))
  }

  useEffect(() => {
    const find_ebook = ebooks.find(ebook => (ebook.id === id ? ebook : 0))
    setEbook(find_ebook)
    let ebook_image = 0
    try {
      ebook_image = require(`../../images/pictures/ebooks/${find_ebook.id}.png`)
    } catch (e) {
      ebook_image = success
    }

    setImage(ebook_image || success)
  }, [id])

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: -50,
    })
  })

  return (
    <div id="mini-ebook-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ebook.title} | FABWORK</title>
        <link rel="canonical" href={`http://fab.work/blog/material?id=${id}`} />
      </Helmet>
      <div className="image-container">
        <img data-aos="fade-in" src={image} alt={ebook.title} />
      </div>
      <div>
        <div className="mini-ebook-content">
          <h1 data-aos="fade-right" className="mini-ebook-title">
            Mini e-book: <strong>{ebook.title}</strong>
          </h1>
          <div
            data-aos="fade-right"
            data-aos-delay="50"
            className="mini-ebook-description"
          >
            {ebook.description.map((paragraph, index) => (
              <p
                data-aos="fade-right"
                data-aos-delay={50 + 50 * ((index % 3) + 1)}
                key={index}
              >
                {paragraph}
              </p>
            ))}
          </div>
        </div>
        <form
          id={`mini-ebook-${ebook.id}`}
          className="mini-ebook-form"
          onSubmit={handleSubmitForm}
        >
          <h2>Acesse o e-book completo</h2>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <button
            disabled={!emailIsValid}
            className={`btn ${!emailIsValid ? "disabled" : "abled"}`}
            type="submit"
          >
            baixar e-book
          </button>
        </form>
      </div>
    </div>
  )
}

export default MiniEbookPage
